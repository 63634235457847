<template>
  <div id="app">
    <header>
      <h1>API App</h1>

      <nav>
        <router-link
            v-for="item in navigation"
            :key="item.name"
            :to="{ name: item.name }"
            class="nav-link"
        >
          {{ item.text }}
        </router-link>
      </nav>
    </header>

    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>

    <footer>
      <p>2024 &copy; Moja aplikacja</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    navigation() {
      return [
        { name: "HomepageView", text: "Strona główna" },
        { name: "LoginView", text: "Logowanie" },
        { name: "RegisterView", text: "Rejestracja" },
      ];
    },
  }
};
</script>

<style>
/* Ogólny styl aplikacji */
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

body{
  margin: 0;
}

header {
  background-color: #34495e;
  padding: 20px 0;
  color: #fff;
}

header h1 {
  margin: 0;
  font-size: 2rem;
}

nav {
  margin-top: 10px;
}

.nav-link {
  text-decoration: none;
  color: #ecf0f1;
  margin: 0 10px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #1abc9c;
}

/* Główna sekcja */
main {
  flex: 1;
  padding: 20px;
}

/* Przejście fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Stopka */
footer {
  background-color: #34495e;
  color: #ecf0f1;
  padding: 10px 0;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}
</style>
