export const routes = [
    { path: "/", name:'HomepageView', component: ()=> import("../views/HomepageView.vue") },
    { path: "/register", name:'RegisterView', component: ()=> import("../views/RegisterView.vue") },
    { path: "/login", name:'LoginView', component: ()=> import("../views/LoginView.vue") },
    { path: "/forgotPassword", name:'ForgotPasswordView', component: ()=> import("../views/ForgotPasswordView.vue") },
    { path: "/verifyToken/:token", name: "EmailVerificationView", component: ()=> import("../views/EmailVerificationView.vue")},


    // Always on bottom
    {
        name: 'ErrorView',
        path: "/:pathMatch(.*)*",
        component: () => import(/* webpackChunkName: "error-view" */ "../views/ErrorView.vue"),
    },
]